var render = function () {
  var _vm$signUp$config, _vm$signUp$config2, _vm$signUp$config3, _vm$signUp$config4, _vm$signUp$config5, _vm$signUp$config6;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sign-up__registration-step"
  }, [!_vm.hideTitle ? _c('div', {
    staticClass: "sign-up__title"
  }, [_vm._v("\n        Регистрация на Workle Pro\n    ")]) : _vm._e(), _vm._v(" "), _c('app-input', {
    directives: [{
      name: "validate-field",
      rawName: "v-validate-field",
      value: {
        validation: _vm.validation
      },
      expression: "{ validation }"
    }],
    ref: "$loginInput",
    staticClass: "sign-up__input",
    attrs: {
      "disabled": _vm.isLoading.global,
      "errors": _vm.loginInfo.errors,
      "name": _vm.loginInfo.name,
      "placeholder": _vm.loginInfo.text,
      "mask": _vm.loginMask
    },
    model: {
      value: _vm.login,
      callback: function ($$v) {
        _vm.login = $$v;
      },
      expression: "login"
    }
  }), _vm._v(" "), (_vm$signUp$config = _vm.signUp.config) !== null && _vm$signUp$config !== void 0 && _vm$signUp$config.requireActivityType ? _c('app-select', {
    directives: [{
      name: "validate-field",
      rawName: "v-validate-field",
      value: {
        validation: _vm.validation
      },
      expression: "{ validation }"
    }],
    staticClass: "sign-up__input",
    attrs: {
      "filterable": "",
      "name": "activityTypeId",
      "searchable": "",
      "placeholder": "Cфера деятельности компании*",
      "disabled": _vm.isLoading.activityTypes || _vm.isLoading.global,
      "errors": _vm.errors.activityTypeId,
      "options": _vm.activityTypes
    },
    model: {
      value: _vm.activityTypeId,
      callback: function ($$v) {
        _vm.activityTypeId = $$v;
      },
      expression: "activityTypeId"
    }
  }) : _vm._e(), _vm._v(" "), _vm.isAdminLayout ? _c('captcha-component', {
    ref: "$captcha"
  }) : _vm._e(), _vm._v(" "), _c('app-checkbox', {
    directives: [{
      name: "validate-field",
      rawName: "v-validate-field",
      value: {
        validation: _vm.validation
      },
      expression: "{ validation }"
    }],
    staticClass: "sign-up__agreed",
    attrs: {
      "font-size": "14px",
      "name": "confirmed",
      "text-align": "left",
      "disabled": _vm.isLoading.global,
      "errors": _vm.errors.confirmed
    },
    model: {
      value: _vm.confirmed,
      callback: function ($$v) {
        _vm.confirmed = $$v;
      },
      expression: "confirmed"
    }
  }, [_vm._v("\n        Я соглашаюсь с\n        "), (_vm$signUp$config2 = _vm.signUp.config) !== null && _vm$signUp$config2 !== void 0 && _vm$signUp$config2.offerLink ? [_vm._v("\n            условиями\n            "), _c('a', {
    attrs: {
      "href": _vm.signUp.config.offerLink,
      "target": "_blank"
    }
  }, [_vm._v("\n                публичной оферты\n            ")])] : _vm._e(), _vm._v(" "), (_vm$signUp$config3 = _vm.signUp.config) !== null && _vm$signUp$config3 !== void 0 && _vm$signUp$config3.personalDataPolicyLink && (_vm$signUp$config4 = _vm.signUp.config) !== null && _vm$signUp$config4 !== void 0 && _vm$signUp$config4.offerLink ? [_vm._v("\n            и\n        ")] : _vm._e(), _vm._v(" "), (_vm$signUp$config5 = _vm.signUp.config) !== null && _vm$signUp$config5 !== void 0 && _vm$signUp$config5.personalDataPolicyLink ? _c('a', {
    attrs: {
      "href": _vm.signUp.config.personalDataPolicyLink,
      "target": "_blank"
    }
  }, [_vm._v("\n            политикой по обработке персональных данных\n        ")]) : _vm._e()], 2), _vm._v(" "), _c('app-button', {
    staticClass: "sign-up__register",
    attrs: {
      "wide": "",
      "color": (_vm$signUp$config6 = _vm.signUp.config) === null || _vm$signUp$config6 === void 0 ? void 0 : _vm$signUp$config6.buttonsColor,
      "disabled": !_vm.captchaToken && _vm.isAdminLayout,
      "loading": _vm.isLoading.global
    },
    on: {
      "on-click": _vm.startRegistration
    }
  }, [_vm._v("\n        Зарегистрироваться\n    ")]), _vm._v(" "), _c('app-button', {
    staticClass: "sign-up__cancel",
    attrs: {
      "flat": "",
      "wide": ""
    },
    on: {
      "on-click": _vm.onLogin
    }
  }, [_vm._v("\n        У меня уже есть профиль\n    ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }