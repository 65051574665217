








































































import { defineComponent, ref } from '@nuxtjs/composition-api'

import WorkleProIcon from '@/assets/img/logo-pro-gray.svg'
import SignUpModal from '@/components/ui/TheAuth/SignUpModal.vue'
import HeaderBurger from '@/components/pages/TheHeader/-HeaderBurger.vue'

import { openedAside, openAside, closeAside } from '@/composables/asides'

export default defineComponent({
    components: {
        WorkleProIcon,
        SignUpModal,
        HeaderBurger
    },
    setup: () => {
        const $signUpModal = ref<InstanceType<typeof SignUpModal> | null>(null)

        const signUp = () => {
            $signUpModal.value?.open()
        }

        return {
            $signUpModal,
            openedAside,
            signUp,
            openAside,
            closeAside

        }
    }
})
