






































































import Inputmask from 'inputmask'

import {
    PropType,
    computed,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
} from '@nuxtjs/composition-api'
import { createPropValidator } from '@/composables/prop-validator'

import CrossIcon from '@/assets/img/cross.svg'

import { numberMask } from '@/composables/masks'
import { compareValues } from '@/composables/utils'
import AppInputFrame from './-AppInputFrame.vue'
import AppInputButton from './-AppInputButton.vue'

const types = [ 'text', 'password', 'email', 'number', 'search', 'tel', 'url' ] as const
type Type = typeof types[number]

const inputmodes = [ 'numeric', 'tel', 'email', 'url', 'none', 'decimal' ] as const
type Inputmode = typeof inputmodes[number]

export default defineComponent({
    components: {
        AppInputFrame,
        AppInputButton,
        CrossIcon
    },
    inheritAttrs: false,
    props: {
        value: {
            type: [ String, Number ],
            default: ''
        },
        type: {
            type: String as PropType<Type>,
            default: 'text',
            validator: createPropValidator({
                componentName: 'AppInput',
                propertyName: 'type',
                allowedValues: types
            })
        },
        inputmode: {
            type: String as PropType<Inputmode | null>,
            default: null
        },
        name: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array as PropType<string[]>,
            default: () => []
        },
        readonly: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        hint: {
            type: String,
            default: ''
        },
        mask: {
            type: [ Object, String ] as PropType<Inputmask.Options | string | null>,
            default: null
        },
        autocomplete: {
            type: String,
            default: 'off'
        },
        placeholder: {
            type: String,
            default: ''
        },
        maxlength: {
            type: Number,
            default: undefined
        },
        showletterslimit: {
            type: Boolean,
            default: true
        }
    },
    setup: (props, { emit }) => {
        const $input = ref<HTMLInputElement | null>(null)
        const removeMask = () => {
            if ($input.value?.inputmask) {
                $input.value.inputmask.remove()
            }
        }
        const updateMask = () => {
            if (!$input.value || (!props.mask && props.type !== 'number')) return undefined

            const mask: Inputmask.Options = {
                showMaskOnHover: false,
                placeholder: '',
                ...(props.type === 'number' ? numberMask : {}),
                ...(typeof props.mask === 'object' ? props.mask : { mask: props.mask })
            }

            removeMask()

            Inputmask(mask).mask($input.value)
        }
        watch(
            () => props.mask,
            (newValue, oldValue) => {
                if (compareValues(newValue, oldValue)) {
                    return undefined
                }

                updateMask()
            },
            { deep: true }
        )

        const onInput = (event: Event) => {
            const { value } = event.target as HTMLInputElement

            emit('input', props.type === 'number' ? parseFloat(value) : value)
        }
        const onClear = () => {
            emit('input', null)
        }

        const focused = ref(false)
        const onFocus = () => {
            focused.value = true
        }
        const onBlur = () => {
            focused.value = false
            emit('blur', { target: { value: props.value } })
        }

        const inputModeByType = computed(() => {
            const inputmodesDict = {
                number: 'numeric',
                tel: 'tel',
                email: 'email',
                url: 'url'
            } as const

            return inputmodesDict[props.type as keyof typeof inputmodesDict] || null
        })

        const actualType = computed(() => (props.type === 'number' ? 'text' : props.type))

        const hasErrors = computed(() => Boolean(props.errors.length))
        const displayingTitle = computed(
            () => (props.type !== 'password' ? props.value : '')
        )

        onMounted(updateMask)
        onBeforeUnmount(removeMask)

        return {
            $input,
            actualType,
            displayingTitle,
            focused,
            hasErrors,
            inputModeByType,
            onBlur,
            onClear,
            onFocus,
            onInput
        }
    }
})
