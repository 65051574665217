

























import { defineComponent } from '@nuxtjs/composition-api'
import SkolkovoLogo from '@/assets/img/skolkovo.svg'

export default defineComponent({
    components: { SkolkovoLogo },
    setup () {
        const currentYear = new Date().getFullYear()

        return { currentYear }
    }
})
