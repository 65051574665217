
























import { SignUp } from '@/composables/sign-up'
import {
    Ref,
    defineComponent,
    inject,
    ref
} from '@nuxtjs/composition-api'

export default defineComponent({
    props: {
        hideTitle: {
            type: Boolean,
            default: true
        }
    },
    setup: () => {
        const signUp = inject('signUp') as Ref<SignUp>

        const isLoading = ref(false)

        const finish = async () => {
            isLoading.value = true

            try {
                await (signUp.value?.finish?.() || signUp.value?.login?.())
            } catch (error) {
                console.error(error)
            }
        }

        return {
            finish,
            signUp
        }
    }
})
