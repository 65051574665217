var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "fill": "inherit",
      "d": "M23.899 4.101A13.9 13.9 0 0014 0a13.9 13.9 0 00-9.899 4.101A13.9 13.9 0 000 14a13.9 13.9 0 004.101 9.899A13.9 13.9 0 0014 28a13.9 13.9 0 009.899-4.101A13.9 13.9 0 0028 14a13.9 13.9 0 00-4.101-9.899zm-1.125 18.673A12.329 12.329 0 0114 26.409c-3.311 0-6.428-1.29-8.774-3.635-4.837-4.838-4.837-12.71 0-17.548A12.329 12.329 0 0114 1.591c3.311 0 6.428 1.29 8.774 3.635 4.837 4.838 4.837 12.71 0 17.548z"
    }
  }), _c('path', {
    attrs: {
      "fill": "inherit",
      "d": "M19.188 8.812a.667.667 0 00-.947 0L14 13.053l-4.241-4.24a.667.667 0 00-.947 0 .667.667 0 000 .945L13.054 14 8.812 18.24a.667.667 0 000 .946.673.673 0 00.471.198.653.653 0 00.47-.198l4.242-4.24 4.241 4.24a.673.673 0 00.471.198.645.645 0 00.47-.198.667.667 0 000-.946L14.948 14l4.24-4.242a.667.667 0 000-.946z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }