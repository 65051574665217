import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4d8eaf14 = () => interopDefault(import('../src/pages/guest/publications/index.vue' /* webpackChunkName: "pages/guest/publications/index" */))
const _3b2a5c2d = () => interopDefault(import('../src/pages/guest/showcases/index.vue' /* webpackChunkName: "pages/guest/showcases/index" */))
const _7eb71a8d = () => interopDefault(import('../src/pages/guest/request/fail/index.vue' /* webpackChunkName: "pages/guest/request/fail/index" */))
const _2c6455ec = () => interopDefault(import('../src/pages/guest/request/success/index.vue' /* webpackChunkName: "pages/guest/request/success/index" */))
const _24a48a57 = () => interopDefault(import('../src/pages/guest/articles/_id/index.vue' /* webpackChunkName: "pages/guest/articles/_id/index" */))
const _5f535fd5 = () => interopDefault(import('../src/pages/guest/c/_id/index.vue' /* webpackChunkName: "pages/guest/c/_id/index" */))
const _1c17ae5e = () => interopDefault(import('../src/pages/guest/product/_id.vue' /* webpackChunkName: "pages/guest/product/_id" */))
const _39fc9861 = () => interopDefault(import('../src/pages/guest/publications/_id/index.vue' /* webpackChunkName: "pages/guest/publications/_id/index" */))
const _48b149d6 = () => interopDefault(import('../src/pages/guest/questionnaire/_id/index.vue' /* webpackChunkName: "pages/guest/questionnaire/_id/index" */))
const _3fe8f456 = () => interopDefault(import('../src/pages/guest/showcases/_id.vue' /* webpackChunkName: "pages/guest/showcases/_id" */))
const _7c636a76 = () => interopDefault(import('../src/pages/guest/stories/_id/index.vue' /* webpackChunkName: "pages/guest/stories/_id/index" */))
const _510a00d4 = () => interopDefault(import('../src/pages/guest/index.vue' /* webpackChunkName: "pages/guest/index" */))
const _4e629400 = () => interopDefault(import('../src/pages/guest/_executorId/p/_id/index.vue' /* webpackChunkName: "pages/guest/_executorId/p/_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/publications",
    component: _4d8eaf14,
    name: "publications"
  }, {
    path: "/showcases",
    component: _3b2a5c2d,
    name: "showcases"
  }, {
    path: "/request/fail",
    component: _7eb71a8d,
    name: "request-fail"
  }, {
    path: "/request/success",
    component: _2c6455ec,
    name: "request-success"
  }, {
    path: "/articles/:id",
    component: _24a48a57,
    name: "articles-id"
  }, {
    path: "/c/:id",
    component: _5f535fd5,
    name: "c-id"
  }, {
    path: "/product/:id?",
    component: _1c17ae5e,
    name: "product-id"
  }, {
    path: "/publications/:id",
    component: _39fc9861,
    name: "publications-id"
  }, {
    path: "/questionnaire/:id",
    component: _48b149d6,
    name: "questionnaire-id"
  }, {
    path: "/showcases/:id",
    component: _3fe8f456,
    name: "showcases-id"
  }, {
    path: "/stories/:id",
    component: _7c636a76,
    name: "stories-id"
  }, {
    path: "/",
    component: _510a00d4,
    name: "index"
  }, {
    path: "/:executorId/p/:id",
    component: _4e629400,
    name: "executorId-p-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
