import { render, staticRenderFns } from "./AppModal.vue?vue&type=template&id=7050b09e&scoped=true&"
import script from "./AppModal.vue?vue&type=script&lang=ts&"
export * from "./AppModal.vue?vue&type=script&lang=ts&"
import style0 from "./AppModal.vue?vue&type=style&index=0&id=7050b09e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7050b09e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppButton: require('/var/www/app/src/components/ui/AppButton.vue').default,BaseModal: require('/var/www/app/src/components/ui/BaseModal.vue').default})
